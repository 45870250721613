/*!

=========================================================
* BLK Design System PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

export default function NerdiacsFooter() {
  return (
    <>
      <footer className="footer">
        <Container>
          <Row className="align-items-baseline">
            <Col>
              <h1 className="title">Nerdiacs</h1>
            </Col>
            <Col md="3">
              <div className="btn-wrapper profile text-left">
                <span>Copyright 2023</span>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}
