/*!

=========================================================
* BLK Design System PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Badge, Container, Row, Col} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import NerdiacsFooter from "components/Footers/NerdiacsFooter.js";

export default function AndroidDeviceWall() {
  const wrapper = React.useRef(null);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    wrapper.current.scrollTop = 0;
    document.body.classList.add("blog-post");
    return function cleanup() {
      document.body.classList.remove("blog-post");
    };
  }, []);
  return (
    <>
      <ColorNavbar />
      <div className="wrapper" ref={wrapper}>
        <div className="section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h6 className="category">Client - TheAlphabetCollective</h6>
                <h3 className="title">Android Device Wall at MWC - Google</h3>
                <h4 className="title">Brief</h4>
                <p>
                  Can we show how Android, the world’s most popular mobile operating system works seamlessly on any device, for the visitors to Mobile World Congress in Barcelona?
                  <br />
                </p>
                <br />
                <h4 className="title">Result</h4>
                <p>
                  Nerdiacs in partnership with Alphabet's team delivered a fully connected 'device wall' replicating a wave, that played 6K footage across 143 different devices from a variety of manufacturers.
                  We designed, created and built software, hardware, infrastructure and metalwork to power and synchronise devices from smartwatches, smartphones, tablets and TVs
                  all running Android. Alphabet’s code with some serious maximum effort and magic brought a harmonious experience display to Barcelona. This becomes androids
                  showpiece for Mobile World Congress, opening their event with a press conference, then continuing to run autonomously for the duration of the event with day and
                  night mode displaying killer visuals impressed visitors and the even the competition.
                </p>
                <br />
                <br />
                <br />
                <iframe className="embed-responsive"
                  src="https://player.vimeo.com/video/337390169?h=9d074383a8"
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h3 className="title">Technical Details:</h3>
                <p>
                  Our team mate Faizan wrote a technical blog post about this project at{" "}
                  <a className="text-white title" target="_blank" href="https://www.faixan.com/p/project-android-wall">
                    <i class="tim-icons icon-link-72 d-inline text-success"></i> Project: Android Wall
                  </a>
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section section-blog-info">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <Row>
                  <Col md="10">
                    <div className="blog-tags">
                      Tags:  <Badge color="primary">Experiential Marketing</Badge>
                    </div>
                  </Col>
                  <hr />
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <NerdiacsFooter />
      </div>
    </>
  );
}
