/*!

=========================================================
* BLK Design System PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Badge, Container, Row, Col } from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import NerdiacsFooter from "components/Footers/NerdiacsFooter.js";


export default function HyundaiBCICar() {
  const wrapper = React.useRef(null);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    wrapper.current.scrollTop = 0;
    document.body.classList.add("blog-post");
    return function cleanup() {
      document.body.classList.remove("blog-post");
    };
  }, []);
  return (
    <>
      <ColorNavbar />
      <div className="wrapper" ref={wrapper}>
        <div className="section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h6 className="category">Client - TheAlphaBetCollective</h6>
                <h3 className="title">Driven by Optimism - Hyundai</h3>
                <h4 className="title">Brief</h4>
                <p>
                  Can we drive a Hyundai i20 with the Power of Thought? That was the brief. <br />
                </p>
                <br />
                <h4 className="title">Result</h4>
                <p>
                  Our tech gurus helped build a software for TheAlphabetCollective and delivered an integrated electronics and Electroencephalogram (EEG) app platform that allowed
                  users to control the custom rigged Hyundai i20 with their minds by simply focussing their positivity and intent. Sounds pretty mental. It was. <br />
                  This was an experiential collaboration with Gravity Thinking that has had over 1.4 million views on Youtube and has recently won The Drum award for Best
                  Automotive Strategy campaign 2016.
                </p>
                <br />
                <br />
                <br />
                <iframe className="embed-responsive"
                  src="https://player.vimeo.com/video/195454888?h=b9449377bc"
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h3 className="title">Technical Details:</h3>
                <p>
                  Our team mate Faizan wrote a technical blog post about this project at{" "}
                  <a className="text-white title" target="_blank" href="https://www.faixan.com/p/project-driven-by-optimism-hyundai">
                    <i class="tim-icons icon-link-72 d-inline text-success"></i> Project: Driven by Optimism | Hyundai 
                  </a>
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section section-blog-info">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <Row>
                  <Col md="10">
                    <div className="blog-tags">
                      Tags:  <Badge color="primary">Experiential Marketing</Badge>
                    </div>
                  </Col>
                  <hr />
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <NerdiacsFooter />
      </div>
    </>
  );
}
