/*!

=========================================================
* BLK Design System PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Badge, Container, Row, Col } from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import NerdiacsFooter from "components/Footers/NerdiacsFooter.js";

export default function HyundaiLTW() {
  const wrapper = React.useRef(null);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    wrapper.current.scrollTop = 0;
    document.body.classList.add("blog-post");
    return function cleanup() {
      document.body.classList.remove("blog-post");
    };
  }, []);
  return (
    <>
      <ColorNavbar />
      <div className="wrapper" ref={wrapper}>
        <div className="section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h6 className="category">Client - TheAlphaBetCollective</h6>
                <h3 className="title">Light the Way - Hyundai</h3>
                <h4 className="title">Brief</h4>
                <p>
                  Can we ‘Light the Way’ of Hyundai’s newest electric car, the iONiQ with a fleet of programmable quadcopters, in a darkened hangar?
                  <br />
                </p>
                <br />
                <h4 className="title">Result</h4>
                <p>
                  We assisted Alphabet's team on the software side to deliver an Automated centrally controlled quadcopter platform that allowed a single user control the custom
                  rigged drones from one device. <br />
                  This was an experiential collaboration with Gravity Thinking that has had over 2 million views on Youtube proving smart technology, paired with innovative
                  concepts creates visually striking content.
                </p>
                <br />
                <br />
                <br />
                <iframe className="embed-responsive"
                  src="https://player.vimeo.com/video/202356166?h=0c6b28f054"
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </Col>
            </Row>
            <Row></Row>
          </Container>
        </div>
        <div className="section section-blog-info">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <Row>
                  <Col md="10">
                    <div className="blog-tags">
                      Tags:  <Badge color="primary">Experiential Marketing</Badge>
                    </div>
                  </Col>
                  <hr />
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <NerdiacsFooter />
      </div>
    </>
  );
}
