/*!

=========================================================
* BLK Design System PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import NerdiacsFooter from "components/Footers/NerdiacsFooter.js";
import ScrollNavbar from "components/Navbars/ScrollNavbar.js";
// nodejs library that concatenates classes
import classnames from "classnames";
// ReactJS plugin for a nice carousel
import Slick from "react-slick";

// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  Label,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  Badge,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  TabContent,
  TabPane,
  UncontrolledTooltip,
} from "reactstrap";

// Sections for this page
// (we've divided this page into multiple react components to make it a bit more readable)
import Basic from "./IndexSections/Basic.js";
import Navbars from "./IndexSections/Navbars.js";
import Tabs from "./IndexSections/Tabs.js";
import Pills from "./IndexSections/Pills.js";
import Pagination from "./IndexSections/Pagination.js";
import Notifications from "./IndexSections/Notifications.js";
import PreFooter from "./IndexSections/PreFooter.js";
import Footers from "./IndexSections/Footers.js";
import Typography from "./IndexSections/Typography.js";
import ContentAreas from "./IndexSections/ContentAreas.js";
import Cards from "./IndexSections/Cards.js";
import PlainCards from "./IndexSections/PlainCards.js";
import JavaScript from "./IndexSections/JavaScript.js";
import NucleoIcons from "./IndexSections/NucleoIcons.js";

export default function Main() {
  const wrapper = React.useRef(null);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    wrapper.current.scrollTop = 0;
    document.body.classList.add("index-page");
    return function cleanup() {
      document.body.classList.remove("index-page");
    };
  }, []);
  const [focus, setFocus] = React.useState(false);
  return (
    <>
      <div className="wrapper" ref={wrapper}>
        <div className="main">
          {/* ********* HEADER 5 ********* */}
          <div className="header header-5">
            <ColorNavbar />
            <div className="page-header">
              <Container>
                <Row className="align-items-center mt-md-5 mr-5 ml-5">
                  <Col className="ml-auto mr-auto" lg="6" md="8">
                    <div className="logo">
                      {/* <div className="logo-image"> */}
                      <img alt="..." src={require("assets/img/nerdiacs-logo-128.png")} />
                      <span className="display-1 ml-2"><br/>Nerdiacs</span>
                      {/* </div> */}
                    </div>
                    <h2 className="title text-left">A creative technology partner that helps bring your ideas to life.</h2>
                  </Col>
                  <Col lg="5" xs="12">
                    <img alt="..." className="path path3" src={require("assets/img/shape-s.png")} />
                    {/* SVG Illustration */}
                    <figure className=" header-shape">
                      <svg
                        className=" injected-svg js-svg-injector"
                        style={{ enableBackground: "new 10 12 878.9 907" }}
                        viewBox="10 12 878.9 907"
                        x="0px"
                        y="0px"
                        xmlSpace="preserve"
                      >
                        <g>
                          <defs>
                            <path
                              d="M300.34,75.35C379.42-7.43,305.86,185.78,540.87,98.95,647.68,46,677,219,674.65,258.55c-11,185-132.32,65-13.89,317.66,56.86,121.32-94.88,256-155.89,151.41-55.11-94.48-151.56-85.1-189-90.54-311-45.27-9.33-148.52-125.21-256.78C9.7,211.2,190.5,100.86,306.34,70.35Z"
                              id="shape1"
                            />
                          </defs>
                          <clipPath id="shape2">
                            <use style={{ overflow: "visible" }} xlinkHref="#shape1" />
                          </clipPath>
                          <g clipPath="url(#shape2)">
                            <image
                              height="900"
                              id="imageShape1"
                              style={{ overflow: "visible" }}
                              transform="matrix(0.9488 0 0 0.9488 25 53.1187)"
                              width="800"
                              xlinkHref={require("assets/img/img_352.png")}
                            />
                          </g>
                        </g>
                        <g>
                          <defs>
                            <path
                              d="M186.26,647.36c7,1,14,1.87,21.11,2.4,42.73,3.24,173.84,9.32,234.51,60.15,72.83,61,105.38,80.19,37.4,96.45C388.73,828,438.49,724,312,749.28c-167.3,33.46-210.61-70.86-181.08-90.54C151.8,644.8,174.69,645.67,186.26,647.36Z"
                              id="shape3"
                            />
                          </defs>
                          <clipPath id="shape4">
                            <use style={{ overflow: "visible" }} xlinkHref="#shape3" />
                          </clipPath>
                          <g clipPath="url(#shape4)" transform="matrix(1 0 0 1 0 0)">
                            <image
                              height="900"
                              id="imageShape2"
                              style={{ overflow: "visible" }}
                              transform="matrix(0.9488 0 0 0.9488 25 53.1187)"
                              width="900"
                              xlinkHref={require("assets/img/img_352.png")}
                            />
                          </g>
                        </g>
                      </svg>
                    </figure>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
          {/* ********* END HEADER 5 ********* */}
          {/* ********* PROJECTS 1 ********* */}
          <div className="projects-1" id="projects">
            <Container>
              <Row>
                <Col className="mr-auto ml-auto text-center mb-5" lg="8">
                  <h2 className="title">Some of Our Awesome Projects</h2>
                </Col>
              </Row>
            </Container>
            <Container>
              <Row>
                <Col md="4">
                  <Card>
                    <CardHeader className="mt-2">
                      <div className="float-left">
                        <i className="tim-icons icon-tag d-inline text-info" />
                        <p className="d-inline ml-1">Art Of Feel</p>
                      </div>
                    </CardHeader>
                    <CardBody className="text-center p-4">
                      <a href="/projects/aof">
                        <img alt="..." className="img-fluid rounded-circle shadow" src={require("assets/img/samsung-artoffeel-image-2.png")} style={{ width: "200px" }} />
                      </a>
                      <CardTitle className="mb-0" tag="h4">
                        Art of Feel - Samsung
                      </CardTitle>
                      <p className="card-description">A project done in collaboration with Holler to invoke feelings from art.</p>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card>
                    <CardHeader className="mt-2">
                      <div className="float-left">
                        <i className="tim-icons icon-tag d-inline text-info" />
                        <p className="d-inline ml-1">Grabber</p>
                      </div>
                    </CardHeader>
                    <CardBody className="text-center p-4">
                      <a href="/projects/grabber">
                        <img alt="..." className="img-fluid rounded-circle shadow" src={require("assets/img/grabber-accenture-1.png")} style={{ width: "100px" }} />
                      </a>
                      <CardTitle className="mb-0" tag="h4">
                        Grabber - Accenture
                      </CardTitle>
                      <p className="card-description">A project done in collaboration with Hothouse to show the fun side of Accenture.</p>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card>
                    <CardHeader className="mt-2">
                      <div className="float-left">
                        <i className="tim-icons icon-tag d-inline text-info" />
                        <p className="d-inline ml-1">Android Device Wall</p>
                      </div>
                    </CardHeader>
                    <CardBody className="text-center p-4">
                      <a href="/projects/android_device_wall">
                        <img alt="..." className="img-fluid rounded-circle shadow" src={require("assets/img/android_wall_1.png")} style={{ width: "100px" }} />
                      </a>
                      <CardTitle className="mb-0" tag="h4">
                        Android Device Wall - Google
                      </CardTitle>
                      <p className="card-description">A project done in collaboration with TheAlphaBetCollective to show the diversity of Android devices.</p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Card>
                    <CardHeader className="mt-2">
                      <div className="float-left">
                        <i className="tim-icons icon-tag d-inline text-info" />
                        <p className="d-inline ml-1">Driven by Optimism</p>
                      </div>
                    </CardHeader>
                    <CardBody className="text-center p-4">
                      <a href="/projects/hyundai_bci">
                        <img alt="..." className="img-fluid rounded-circle shadow" src={require("assets/img/hyundai_bci_1.png")} style={{ width: "100px" }} />
                      </a>
                      <CardTitle className="mb-0" tag="h4">
                        Driven by Optimism - Hyundai
                      </CardTitle>
                      <p className="card-description">A project that used Brain Control Interface (BCI) to drive Hyundai cars.</p>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card>
                    <CardHeader className="mt-2">
                      <div className="float-left">
                        <i className="tim-icons icon-tag d-inline text-info" />
                        <p className="d-inline ml-1">Light the Way</p>
                      </div>
                    </CardHeader>
                    <CardBody className="text-center p-4">
                      <a href="/projects/hyundai_ltw">
                        <img alt="..." className="img-fluid rounded-circle shadow" src={require("assets/img/hyundai_ltw_1.png")} style={{ width: "100px" }} />
                      </a>
                      <CardTitle className="mb-0" tag="h4">
                        Light the Way - Hyundai
                      </CardTitle>
                      <p className="card-description">A project to control a fleet of Drones for Hyundai's i20 campaign.</p>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card>
                    <CardHeader className="mt-2">
                      <div className="float-left">
                        <i className="tim-icons icon-tag d-inline text-info" />
                        <p className="d-inline ml-1">World Choir</p>
                      </div>
                    </CardHeader>
                    <CardBody className="text-center p-4">
                      <a href="/projects/samsung_choir">
                        <img alt="..." className="img-fluid rounded-circle shadow" src={require("assets/img/samsung_choir.jpeg")} style={{ width: "100px" }} />
                      </a>
                      <CardTitle className="mb-0" tag="h4">
                        World Choir - Samsung
                      </CardTitle>
                      <p className="card-description">
                        A project using biometrics and Samsung S3 watch sensors to create complimenting visualisations for a Choir in Piccadilly Circus London UK .
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
          {/* ********* END PROJECTS 1 ********* */}
          {/* ********* CONTACT US 3 ********* */}
          <div className="contactus-3" id="contactus">
            <div className="header-filter">
              <Container>
                <Row>
                  <Col className="text-center" md="12">
                    <h1 className="title">Got a question?</h1>
                    <h3>We'd like to talk more about what you need</h3>
                  </Col>
                </Row>
              </Container>
            </div>
            <Container>
              <Row>
                <Col className="col-">
                  <div className="info info-hover">
                    <div className="icon icon-primary">
                      <img alt="..." className="bg-blob" src={require("assets/img/feature-blob/primary.png")} />
                      <i className="tim-icons icon-square-pin" />
                    </div>
                    <h4 className="info-title">Address</h4>
                    <p className="description">Nerdiacs Pte Ltd 7, Temasek Boulevard #12-07 Suntec Tower One Singapore 038987</p>
                  </div>
                </Col>
                <Col>
                  <div className="info info-hover">
                    <div className="icon icon-info">
                      <img alt="..." className="bg-blob" src={require("assets/img/feature-blob/info.png")} />
                      <i className="tim-icons icon-email-85" />
                    </div>
                    <h4 className="info-title">Email</h4>
                    <p className="description">info@nerdiacs.com</p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* ********* END CONTACT US 3 ********* */}
        </div>
        <NerdiacsFooter />
      </div>
    </>
  );
}
