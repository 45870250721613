/*!

=========================================================
* BLK Design System PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Badge, Container, Row, Col } from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import NerdiacsFooter from "components/Footers/NerdiacsFooter.js";

export default function ArtOfFeel() {
  const wrapper = React.useRef(null);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    wrapper.current.scrollTop = 0;
    document.body.classList.add("blog-post");
    return function cleanup() {
      document.body.classList.remove("blog-post");
    };
  }, []);
  return (
    <>
      <ColorNavbar />
      <div className="wrapper" ref={wrapper}>
        <div className="section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h6 className="category">Client - Holler</h6>
                <h3 className="title">Art Of Feel Project - Samsung</h3>
                <h4 className="title">Brief</h4>
                <p>
                  Samsung requested to present Samsung S6 as a device for art lovers. ArtOfFeel campaign was created by Holler to allow everyone visiting select galleries to be an
                  art critic and get a customized generated art based on what users like. <br />
                </p>
                <br />
                <h4 className="title">Result</h4>
                <p>
                  We built an android application running on Samsung S6 that utilized NFC and iBeacons to detect location of user in gallery and present them with information about
                  specific art they are interested in. The feedback as to what a user felt forÂ each art piece was stored in backend which helped in generating a custom art piece
                  for user at end of trip. The whole setup required building android application, a robust backend, a CMS for updating of art pieces and NFC information. A good
                  analytics system was also integrated to get insights on usage of application during the event.
                </p>
                <br />
                <br />
                <br />
                <iframe className="embed-responsive"
                  src="https://player.vimeo.com/video/140905812?h=a4462e4b30"
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto" md="8"></Col>
            </Row>
          </Container>
        </div>
        <div className="section section-blog-info">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <Row>
                  <Col md="10">
                    <div className="blog-tags">
                      Tags:  <Badge color="primary">Experiential Marketing</Badge>
                    </div>
                  </Col>
                  <hr />
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <NerdiacsFooter />
      </div>
    </>
  );
}
