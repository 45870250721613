/*!

=========================================================
* BLK Design System PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Badge, Container, Row, Col } from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import NerdiacsFooter from "components/Footers/NerdiacsFooter.js";

export default function SamsungWatchChoir() {
  const wrapper = React.useRef(null);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    wrapper.current.scrollTop = 0;
    document.body.classList.add("blog-post");
    return function cleanup() {
      document.body.classList.remove("blog-post");
    };
  }, []);
  return (
    <>
      <ColorNavbar />
      <div className="wrapper" ref={wrapper}>
        <div className="section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h6 className="category">Client - TheAlphaBetCollective</h6>
                <h3 className="title">Choir - Samsung</h3>
                <h4 className="title">Brief</h4>
                <p>
                  Can we visualise ‘The Emotion of Connectivity’ for the launch of the Samsung S3 Smart watch on the world's first biometrical stage in Piccadilly Circus London UK.
                  <br />
                </p>
                <br />
                <h4 className="title">Result</h4>
                <p>
                  We assisted Alphabet’s team in delivering a centrally controlled biometrics analysis application that allowed live data to be collected and transmitted to a webGL
                  visualisation platform. <br />
                  <br />
                  Alphabet’s team delivered a centrally controlled biometrics analysis application that allowed live data to be collected and transmitted to a webGL visualisation
                  platform.
                  <br />
                  <br />
                  This was an experiential collaboration with Framestore Labs that saw 24 various choirs from around the world perform on the Eros stage. Each of the choir wore an
                  S3 watch to read their biometrics in realtime as they performed. Our custom software threw this data to the Framsestore Labs visualisation set up. Coupling our
                  code with their webGL visuals allowed the system to display 'The Emotions' of the performers in real time for 24 days in the run up to Christmas, one of the
                  busiest periods for Piccadilly Circus.
                  <br />
                  <br />
                  The system ran autonomously for over a month displaying the biometric data from the choir on the numerous screens throughout the Eros site to be viewed by close
                  to 8 million people proving you can see emotion.
                </p>
                <br />
                <br />
                <iframe
                  className="embed-responsive"
                  src="https://player.vimeo.com/video/206037179?h=5b896c75d0"
                  frameborder="0"
                  allow="fullscreen; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </Col>
            </Row>
            <Row></Row>
          </Container>
        </div>
        <div className="section section-blog-info">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <Row>
                  <Col md="10">
                    <div className="blog-tags">
                      Tags:  <Badge color="primary">Experiential Marketing</Badge>
                    </div>
                  </Col>
                  <hr />
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <NerdiacsFooter />
      </div>
    </>
  );
}
