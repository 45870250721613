/*!

=========================================================
* BLK Design System PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// ReactJS plugin for a nice carousel

// reactstrap components
import { Badge, Container, Row, Col } from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import NerdiacsFooter from "components/Footers/NerdiacsFooter.js";

export default function Grabber() {
  const wrapper = React.useRef(null);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    wrapper.current.scrollTop = 0;
    document.body.classList.add("blog-post");
    return function cleanup() {
      document.body.classList.remove("blog-post");
    };
  }, []);
  return (
    <>
      <ColorNavbar />
      <div className="wrapper" ref={wrapper}>
        <div className="section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h6 className="category">Client - Hothouse</h6>
                <h3 className="title">Grabber - Accenture</h3>
                <h4 className="title">Brief</h4>
                <p>
                  Client wanted an interactive grabber game which could be playedÂ via a touch screen or via your own mobile phone via web.
                  <br />
                </p>
                <br />
                <h4 className="title">Result</h4>
                <p>
                  We built an android application running on Nexus 10. The communication to grabber game isÂ done via arduino and relays that in turn directÂ the movement of
                  grabber claw. The claw drop and win/lose event received from grabber game are relayed to mobile web via an intermediary server built with nodeJS.
                </p>
                <br />
                <br />
                <br />
                <iframe className="embed-responsive" title="vimeo-player" src="https://player.vimeo.com/video/134706827?h=1a97e21051" frameborder="0" allowfullscreen></iframe>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto" md="8"></Col>
            </Row>
          </Container>
        </div>
        <div className="section section-blog-info">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <Row>
                  <Col md="10">
                    <div className="blog-tags">
                      Tags:  <Badge color="primary">Experiential Marketing</Badge>
                    </div>
                  </Col>
                  <hr />
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <NerdiacsFooter />
      </div>
    </>
  );
}
